@tailwind base;
@tailwind components;
@tailwind utilities;

:root {

  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 0, 0, 0;
  --background-end-rgb: 0, 0, 0;
  /*--foreground-rgb: 0, 0, 0;*/
  /*--background-start-rgb: 214, 219, 220;*/
  /*--background-end-rgb: 255, 255, 255;*/
}

/*@media (prefers-color-scheme: dark) {*/
/*  :root {*/
/*    --foreground-rgb: 255, 255, 255;*/
/*    --background-start-rgb: 0, 0, 0;*/
/*    --background-end-rgb: 0, 0, 0;*/
/*  }*/
/*}*/

body {
  overscroll-behavior-x: none;
  color: rgb(var(--foreground-rgb)) !important;
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

/* Pulse effect */
.play_btn {
  cursor:pointer;
 
  
}

/* css for right menu  */
.bg-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    object-fit: cover;
    z-index: -1;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 168px;
  background-color: rgba(19, 19, 19, 0.5);
  padding: 10px 0;
  z-index: 9;
}

.button-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding: 10px; */
  text-align: center;
  border: 0px solid #ccc;
  background-color:transparent;
  border-radius: 8px;
  /*transition: transform 0.3s;*/
  width:100%;
  padding: 30px 10px;
  color:#FFFFFFFF;
}

.button-item:hover {
  /*transform: scale(1.05); !* Slight hover effect *!*/
}

.selected-button {
   display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100px;
  text-align: center;
  background-color:transparent;
}

.options-list {
  list-style: none;
  padding: 0;
  margin-top: 20px;
  background-color: transparent;
}

.options-list li {
  padding: 5px;
  background-color: transparent;
  margin: 5px 0;
  border-radius: 14px;
  color: #FFFFFFFF;
}

.lrt .dir {
  direction: ltr !important;
}

.rtl .dir {
  direction: rtl !important;
}